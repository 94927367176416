<template>

  <FocusTrap>
    <div class="card" id="myform">

    <div class="card-header header-elements-inline" style="background-color: lightgrey">
      <h5 class="card-title">Brand Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <p/>
      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
        <div class="col-md-10">
          <input  id="txtcode" type="text" class="form-control" placeholder="Enter Name here..." maxlength="100" v-if="brand" v-model="brand.name" autocomplete="off" autofocus>
        </div>
      </div>


      <div class="form-group row">
        <label class="col-md-2 col-form-label font-weight-semibold">Type:</label>
        <div class="col-md-10">
          <select class="form-control" v-if="brand" v-model="brand.type">
            <option value="1">Default</option>
          </select>
        </div>
      </div>

    </div>

    <div class="card-footer">
      <div class="text-right">
        <button type="button" class="btn btn-primary" @click="saveDocument">Save<i class="icon-paperplane ml-2"></i>
        </button>
      </div>
    </div>

  </div>
  </FocusTrap>
</template>

<script>
  import moment from 'moment-timezone'
  import { userService } from '@/store/auth-header.js'
  import { store } from '@/store/store.js'

  export default {
    name: 'BrandForm',
    store,
    component: {},
    data () {
      return {
        brand: {"id":0,  "status":"Active", "name":"","type":1},
        parents: [],
      }
    },
    props: {
      mybrand: {
        type: Object,
        default: () => JSON.parse('{"id":0,  "status":"Active", "name":"","type":1}')
      }
    },
    beforeMount () {
      this.brand =  JSON.parse(JSON.stringify(this.mybrand));
    },
    created () {

    },
    mounted () {
      $('input[type=checkbox]').uniform();
      $('#txtcode').focus();
    },
    methods: {
      closeWindow () {
        this.$emit('brand_window_closed');
      },
      saveDocument () {
        const self = this;
        // self.$data.brand.parent_id = parseInt(self.$data.brand.parent_id)

        if (self.$data.brand.name.trim().length < 1) {
          alert('Invalid Name')
          return
        }


        const requestOptions = {
          method: (self.$data.brand.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.brand)
        }

        $('#myform').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        })

        fetch(`${process.env.VUE_APP_ROOT_API}api/brand`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({ title: 'Success!', text: resp.msg, type: 'success', onClose: () => { $('#txtcode').focus()}, timer:1500 })
            self.brand = JSON.parse('{"id":0,  "status":"Active", "name":"","type":1}')
            self.$emit('brand_saved', resp.data);

          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error', timer:3000 })
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer:3000 })
        }).finally(function () {
          $('#myform').unblock()
        })
      }
    }
  }
</script>

<style scoped>
    input:focus {
      background: #feff00;

    }

    textarea:focus {
      background: #feff00;

    }

    select:focus {
      background: #feff00;
    }

    button:focus {
      background: #feff00;
      color: #0a0a0a!important;
    }
</style>
